import {db} from "../firebase"

class VideoService {
    async getAll() {
        const snapshot = await db.collection("video").get()
        const documents = [];
        snapshot.forEach(doc => {
            const document = { id: doc.id, ...doc.data() };
            documents.push(document);
        })
        return documents.sort((a, b) => a.region.toLowerCase().localeCompare(b.region.toLowerCase()));
    }

    async get(id){
        const video = await db.collection("video").doc(id).get()
        return video.data()
    }

    create(video) {
        db.collection("video").add(video)
            .then((docRef) => {
                console.log(`Document written with ID: ${docRef.id}`)
            })
            .catch((error) => {
                console.error(`Error adding document: ${error}`)
            })
    }

    update(id, value) {
        db.collection("video").doc(id).update(value)
            .then(docRef => {
                console.log(`Document updated: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }

    delete(id) {
        db.collection("video").doc(id).delete()
            .then(docRef => {
                console.log(`Document deleted: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }
}

export default new VideoService();