<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Videos</h1>
        </div>
      </div>
    </div>
    <div class="main container">
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-quarter" v-for="region in video" :key="region.id">
          <a target="_blank" rel="noopener noreferrer" :href="region.link">
            <div class="videoBox">
              <p class="is-size-4 description">{{region.region}}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoService from "../services/videoService";

export default {
  name: "Video",
  data() {
    return {
      video: []
    };
  },
  async mounted() {
    this.video = await VideoService.getAll();
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin-top: 30px;
}

.org-description {
  margin-top: 50px;
}

.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.videoBox {
  background-color: #eee;
  border-radius: 2px;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  filter: drop-shadow(0 0 0.1rem black);
  padding: 30px 65px;
  margin: 10px;
  text-align: center;
}
.videoBox:hover {
  opacity: 1;
}
.videoBox:link {
  text-decoration: none;
}
</style>